<template>
  <div class="user-manage-page">
    <x-table :no-data-text="CA('userManage_check') ? '暂无数据' : '暂无数据查询权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @add="add" @search="search" @page-change="pageChange" @page-size-change="pageSizeChange"></x-table>

    <Modal v-model="modal.show" :title="modal.title" @on-visible-change="modalShow" :width="450">
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <div style="
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
          ">
          <FormItem :show-message="false" style="margin-bottom: 0px" prop="logo" :label-width="0">
            <upload-image :max="1" :clear-flag="!modal.show" v-model="form.avatar">
            </upload-image>
          </FormItem>
          <span style="padding-top: 6px">头像</span>
        </div>
        <FormItem label="用户账号" prop="account">
          <Input v-model="form.account" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="用户名" prop="name">
          <Input v-model="form.name" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="用户部门" prop="dept">
          <Cascader :data="deptList" v-model="form.dept" change-on-select style="width: 300px"></Cascader>
        </FormItem>
        <FormItem label="用户权限" prop="role">
          <Select v-model="form.role" multiple style="width: 300px">
            <Option v-for="item in roleList" :value="item.id" :key="item.id">{{
              item.name
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="联系方式" prop="phone">
          <Input v-model="form.phone" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="电子邮件" prop="email">
          <Input v-model="form.email" style="width: 300px"></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <region-records v-model="regionRecordsList.show" :regionRecordsList="regionRecordsList.info"></region-records>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CU from '@/common/util'
import regionRecords from './regionRecords'
export default {
  name: '',
  components: {
    regionRecords,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '用户账号',
            minWidth: 100,
            key: 'account',
          },
          {
            title: '用户名称',
            mingWidth: 100,
            key: 'name',
          },
          {
            title: '用户角色',
            mingWidth: 100,
            key: 'roleName',
          },
          {
            title: '联系方式',
            mingWidth: 130,
            key: 'phone',
          },
          {
            title: '电子邮箱',
            mingWidth: 100,
            key: 'email',
          },
          {
            title: '操作',
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA('userManage_edit') && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA('userManage_delete') && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: '新增用户',
            ca: 'userManage_add',
          },
          width: 200,
          filterBox: [
            {
              conditionName: '用户名称',
              component: 'input',
              field: 'name',
              defaultValue: '',
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      search_data: {},

      modal: {
        show: false,
        title: '',
        submitLoading: false,
      },
      form: {
        avatar: '',
        name: '',
        account: '',
        dept: [],
        role: [],
        phone: '',
        email: '',
        category: '',
      },
      rules: {
        name: [{ required: true, message: '请填写用户名' }],
        account: [{ required: true, message: '请填写用户账号' }],
        dept: [{ required: true, type: 'array', message: '请选择用户部门' }],
        role: [{ required: true, type: 'array', message: '请选择用户权限' }],
        phone: [
          { required: true, message: '请填写联系方式' },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value) || CU.validateLandline(value)) {
                callback()
                return
              }
              callback(rule.message)
            },
            message: '请填写正确的联系方式',
          },
        ],
      },

      roleList: [],
      deptList: [],
      regionRecordsList: {
        show: false,
        info: {},
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },
    search(data) {
      this.search_data = data
      this.config.page.pageNo = 1
      this.getList()
    },
    add() {
      this.modal = {
        show: true,
        title: '新增用户',
        submitLoading: false,
      }
    },
    getList() {
      this.table.loading = true
      this.$post(this.$api.USER_MANAGE.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        category: 3,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list
          this.config.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    getRole() {
      this.$post(this.$api.ROLE.LIST, {
        pageNo: 1,
        pageSize: 99,
        // roleType: +this.user.category + 1,
        roleType: 3,
      }).then((res) => {
        this.roleList = res.list
      })
    },
    getDept() {
      this.$post(this.$api.DEPT_MANAGE.TREE).then((res) => {
        this.deptList = CU.formatTreeData(res, 'unitId', 'unitName')
      })
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.form.id = row.id
      this.form.dept = CU.findCascaderValue(this.deptList, row.deptid)
      this.form.role = row.roleid.split(',').map((item) => Number(item))
      if (row.avatar == 'imageundefined') {
        this.form.avatar = ''
      } else {
        this.form.avatar = row.servicePath + row.avatar
      }
      this.modal = {
        show: true,
        title: '编辑用户',
        submitLoading: false,
      }
    },

    del(id) {
      this.$post(this.$api.USER_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success('删除成功')
        this.getList()
      })
    },

    modalShow(visible) {
      if (visible) return
      this.form = {
        avatar: '',
        name: '',
        account: '',
        dept: [],
        role: [],
        phone: '',
        email: '',
        category: '',
      }
      this.$refs.form.resetFields()
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        params.deptId = this.form.dept[this.form.dept.length - 1]
        params.roleId = this.form.role.join(',')
        params.avatar = 'image' + this.form.avatar.split('image')[1]
        this.modal.submitLoading = true
        this.$post(
          params.id ? this.$api.USER_MANAGE.EDIT : this.$api.USER_MANAGE.ADD,
          params
        )
          .then(() => {
            this.getList()
            this.modal.show = false
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },

    regionRecords(row) {
      this.regionRecordsList = {
        show: true,
        info: row,
      }
    },
  },
  mounted() {
    this.getList()
    this.getRole()
    this.getDept()
  },
}
</script>

<style lang="less" scoped>
.user-manage-page {
  width: 100%;
  height: 100%;
}
</style>